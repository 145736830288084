.mainBtnContainer {
  position: absolute;
  margin-left: calc(50% + 100px);
  width: 22vw;
  margin-top: -10vw;
  z-index: 40;
}

.mainBtnContainer a {
  text-decoration: none;
  font-size: 1.5vw;
  width: 100%;
  height: 6.5vw;
  padding: 2.5vw;
  display: block;
  text-align: center;
  border-radius: 46px;
  align-content: center;
  background-color: #8e72e134;
}

.mainBtnContainer img {
  display: block;
  width: 22vw; 
  height:20px;
  margin: 1rem auto;
}