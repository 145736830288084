/* Form */
#mainForm {
  display: grid;
  grid-template-columns: repeat(10, 1fr);  
  margin-top: 3vw;
  padding-bottom: 30vw;
  margin-bottom: calc(-1rem - 1.4vw - 3vw);
  background-image: url(../../../../public/images/main/form-bg-01.svg); 
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom left;  
}

.formContainer {
  grid-column: 3 / 9;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.20) 0%, rgba(35, 5, 119, 0.05) 100%);
  /* bacground blur */
  backdrop-filter: blur(10px);
  border: 2px solid #5b5b7e;
  height: auto;
  border-radius: 3.5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.formContainer form {
  width: 85%;
}

.formContainer legend {
  width: 60%;
  background-color: #000476;
  margin: 2.8vw auto 4vw;
  height: auto;
  border-radius: calc(2.4vw + 1.5vw);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.2vw 3vw;
  font-size: 1.3vw;
  font-size: max(1.3vw, 14px);
  font-weight: 200;
}

.formContainer input {
  width: 51%;
  height: 3.5vw;  
  border-radius: 1.75vw;
  border: 1px solid #54b6eb33;
  padding-left: 3vw;  
  font-size: max(1.3vw, 14px);
  font-weight: 200;
  margin-bottom: 0.7vw;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

.formContainer input:focus, .formContainer textarea:focus {
  outline: none;  
}

.formContainer textarea {
  width: 100%;
  height: 18vw;
  border-radius: 1.75vw;
  border: 1px solid #54b6eb33;
  padding-left: 3vw;
  padding-top: 1vw;
  font-size: max(1.3vw, 14px);  
  font-weight: 200;
  margin-bottom: 1.5vw;
  background-color: transparent;
}

.formContainer textarea::placeholder, .formContainer input::placeholder {
  color: #fff;
}

.formContainer input:focus, .formContainer textarea:focus {
  border-color: var(--fourth-color);
  box-shadow: 0vw 0vw 1vw 1vw rgba(255,101,101,0.10);
  -webkit-box-shadow: 0vw 0vw 1vw 1vw rgba(255,101,101,0.10);
  -moz-box-shadow: 0vw 0vw 1vw 1vw rgba(255,101,101,0.10);
}

.formContainer  .submitBtn {  
  background-color: var(--fourth-color);
  width: 25%;
  height: auto;
  border: transparent;
  border-radius: 1vw;
  padding: 1.2vw 2vw;
  font-family: 'Tango Sans', sans-serif;
  font-size: 1.3vw;
  font-size: max(1.3vw, 14px);
  color: #fff;
  margin: 1rem auto 2rem;
  display: block;
  cursor: pointer;
}

.formContainer .iconsContainer  {
  position: absolute;
  top: 5%;
  right: 5%;
}

.formContainer .iconsContainer a {
  height: 1.8vw;
  width: 1.8vw;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  margin-bottom: 1vw;
  transition: all 0.2s ease-in-out;
}

.formContainer .iconsContainer .whatsapp-icon {
  background-image: url(../../../../public/images/main/wa-icon.svg);  
}

.formContainer .iconsContainer .linkedin-icon {
  background-image: url(../../../../public/images/main/llinkedin-icon.svg);  
}

.formContainer .iconsContainer .mail-icon {
  background-image: url(../../../../public/images/main/mail-icon.svg);  
}

.formContainer .iconsContainer .whatsapp-icon:hover {
  background-image: url(../../../../public/images/main/wa-icon-active.svg);  
}

.formContainer .iconsContainer .linkedin-icon:hover {
  background-image: url(../../../../public/images/main/llinkedin-icon-active.svg);  
}

.formContainer .iconsContainer .mail-icon:hover {
  background-image: url(../../../../public/images/main/mail-icon-active.svg);  
}

.formSubmitControl {
  display: flex;
  justify-content: flex-end;
  align-items: center;  

}

.formSubmitControl p {
  height: 100%;    
  font-size: 1.5vw;
  margin: 0 0 1.5vw;  
}

.formSubmitControl .arrowContainer {
  width: 4.5vw; 
  height: 4.5vw;
  border-radius: 50%;
  /* background: rgb(0,21,156); */
  background: linear-gradient(-45deg, rgba(0,21,156,1) 0%, rgba(33,59,133,1) 100%);
  border: 1px white solid;
  display: flex;
  justify-content: center;
  align-items: center;  
  margin: 0 1.5vw 1.5vw;
  transition: all 1s ease-in-out;
}

.formSubmitControl .arrowContainer:hover {
  background: rgb(255,99,99);
  background: linear-gradient(-45deg, rgba(255,99,99,0.5) 0%, rgba(255,99,99,0) 100%);  
}

.formSubmitControl .arrowContainerSended {
  background: rgb(241,255,51);
  background: linear-gradient(-45deg, rgba(241,255,51,0.5) 0%, rgba(241,255,51,0) 100%);
}

.formSubmitControl .arrowContainerSended:hover {
  background: rgb(241,255,51);
  background: linear-gradient(-45deg, rgba(241,255,51,0.5) 0%, rgba(241,255,51,0) 100%);
}

.formSubmitControl .arrowContainer img {
  height: 50%;
  width: auto;
}

.disableBtn .arrowContainer img {
  height: 50%;
  width: auto;
  opacity: 0.2;
}



@media screen and (max-width: 768px) {
  #home #mainForm {
    margin-top: 10vw; 
  }

  .formContainer legend {
    width: 79%;    
  }

  .formContainer input {
    width: 90%; 
    border-radius: 3vw;  
    margin-bottom: 1vw;    
  }

  .formContainer textarea {
    margin-top: 0.5vw;
    border-radius: 3vw; 
  }

  .formContainer input {
    height: 5vw;    
  }

  .formContainer input, .formContainer textarea, .formContainer  .submitBtn, .formContainer legend  {    
    font-size: 2.5vw; 
  }

  .formContainer {    
    border: 1px solid #5b5b7e;    
  }

  .formContainer .iconsContainer  {
    position: absolute;
    bottom: 2%;
    left: 5%;
    top: auto;
    right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .formContainer .iconsContainer a {
    margin-left: 2vw;
    height: 3vw;
    width: 3vw;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: block;
    margin-bottom: 1vw;
    transition: all 0.2s ease-in-out;    
  }
  
}