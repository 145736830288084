.animationContainer {
  width: auto;
  height: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;  
  margin: 0 auto;
  /* border: solid 1px red; */
  margin-bottom: 1vw;
}

.point {
  width: auto;
  height: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;  
  margin: 0 auto;  
  margin-bottom: 1vw;
  padding: 3vw;
}

.point img{
  height: 100%;
  width: auto;
  object-fit: cover;  
}

/* Mobile */
@media screen and (max-width: 768px) {
  #home .animationContainer {    
    height: 15vw;    
  }

  #sectorPrivado .animationContainer, #gestionPublica .animationContainer {    
    height: 12vw; 
    margin-top: 2vw;   
  }
}

