#footerLinks {
    display: block;
    width: 100vw;
    padding: 0 20vw;
    margin: 0 auto -5vw;    
    text-align: center;
    transform: translate(0,  -20vw);    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#footerLinks img {
    height: 6vw;
    width: auto;
    margin: 0 2vw;    
    border-radius: 3vw;    
    transition: all 0.5s ease-in-out;
}

#footerLinks .lnkTSP {    
    background: linear-gradient(90deg, rgba(255,99,99,0.5) 0%, rgba(255,99,99,0) 100%);
    background-size: 0% ;
    background-repeat: no-repeat;    
}

#footerLinks .lnkTGP, #footerLinks .lnkTH {    
    background: linear-gradient(90deg, rgba(241,255,51,0.5) 0%, rgba(241,255,51,0) 100%);
    background-size: 0% ;
    background-repeat: no-repeat; 
}

#footerLinks .lnkTGP:hover, #footerLinks .lnkTSP:hover, #footerLinks .lnkTH:hover {
    background-size: 100% ;
}


@media screen and (max-width: 425px) {
    #footerLinks {        
        transform: translate(0,  -18vw);
    }
}


