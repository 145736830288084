#campana {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  justify-content: center;
  align-items: center;
}

#campana h2 {
  grid-column: 3 / 9;
  font-family: 'Tango Sans', sans-serif;
  text-align: center;
  color: var(--second-color);  
  font-size: 2.8vw;
  font-size: max(2.8vw, 22px); 
  margin-top: 1vw;
  margin-bottom: 1vw; 
}

#campana h3 { 
  grid-column: 3 / 9;
  width: auto;
  margin: 0 auto;
  text-align: center; 
  background-color: #bdccd420; 
  border: solid 1px  #bdccd450;
  font-size: 1.8vw;
  font-size: max(1.8vw, 18px);
  padding: 0.7vw 1.35vw;
}

.campanaContainer {
  grid-column: 3 / 9;
  display: grid;
  grid-template-columns: repeat(2, 1fr);  
  gap: 4.3vw;
  margin-top: 3.6vw;
}

.campanaContainer .content {
  grid-column: auto / span 1;
  display: flex;
  flex-direction: column;   
  height: auto;     
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;  
  background-color: #1F2387;
  border-radius: 1.7vw;  
  cursor: pointer;  
  transition: all 0.3s ease-in-out;
}

.card:hover {    
  background-color: #0a0d5f;  
  background-image: url(../../../../public/images/sector_privado/card-bg-red.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 1.7vw;  
  cursor: pointer;  
}
  
.card:nth-child(1):before, .card:nth-child(1):after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.30), transparent);
  transition: all 0.3s ease-in-out;
}

.card:nth-child(1):hover:before, .card:nth-child(1):hover:after {
  background: linear-gradient(90deg, rgba(211, 255, 51, 0.3), transparent);
}
  
.card:before, .card:after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: #fff;  
  z-index: -1;  
  border-radius: 1.7vw;
}

.campanaContainer .card-content h4 {  
  font-family: 'Rubik', sans-serif;
  color: #fff; 
  font-size: 1.8vw;
  font-size: max(1.6vw, 12px);
  line-height: 1.5vw; 
  line-height: max(1.6vw, 12px);
  font-weight: 400; 
  text-align: center; 
}

.card-content p {
  font-family: 'Tango Sans', sans-serif;  
  color: #fff;  
  font-size: 1vw;
  line-height: 1.2vw;  
}

.campanaCircleContainer {
  grid-column: 1 / 3;    
  position: relative;  
  bottom: 0;
}

.campanaCircle {
  width: 14vw;
  height: 14vw; 
  border-radius: 50%;
  position: absolute;
  background-color: red;
  bottom: -4vw;
  left: 3.2vw;
  border: solid 1px var(--second-color);
  background: rgb(52,102,154);
  background: linear-gradient(-45deg, rgba(52,102,154,1) 0%, rgba(0,0,0,0) 100%);
  opacity: 0.05;
}

/* MOBILE */

@media screen and (max-width: 768px) {
  #campana h2 {    
    font-size: 5vw;  
    line-height: 4.5vw;
    width: 100%;
    margin: 7vw auto 0;
    grid-column: 2 / 10;
  }

  #gestionPublica #campana h3 { 
    margin-top: 3vw;    
    font-size: 3vw
  }

  #campana .campanaContainer {     
    margin-top: 8vw;    
  }

  .campanaContainer .card-content h4 {     
    font-size: 3.1vw;    
    line-height: 3.2vw;     
  }

  .card:before, .card:after {    
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;    
  }

  #gestionPublica .card {    
    border-radius: 4.5vw; 
    background-image: url(../../../../public/images/sector_privado/card-bg-red.svg);
  }

  #gestionPublica .card:before, #gestionPublica .card:after {    
    border-radius: 4.5vw;
  }

  #gestionPublica .card:hover {  
    border-radius: 4.5vw;       
  }

  #gestionPublica .card:nth-child(1):before, #gestionPublica .card:nth-child(1):after {    
    background: linear-gradient(90deg, rgba(211, 255, 51, 0.3), transparent);
    transition: all 0.3s ease-in-out;
  }
}

