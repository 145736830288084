/* CIRCLES */

.circle-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 20;
    top: -40vh;
    right: 10%;
  }
  
  .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    opacity: 0.2;
    position: absolute;
    animation: expand 2s ease-in-out infinite;
    background-color: #ff14917e;
  }
  
  .circle-1 {   
    animation-delay: 0s;
  }
  
  .circle-2 {  
    width: 75px;
    height: 75px;  
    animation-delay: 0.2s;
  }
  
  .circle-3 {
    width: 50px;
    height: 50px;    
    animation-delay: 0.4s;
  }
  
  .circle-4 {
    width: 25px;
    height: 25px;
    animation-delay: 0.6s;
  }
  
  @keyframes expand {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.15);
    }
    100% {
      transform: scale(1);
    }
  }