.content {
  display: flex;
  flex-direction: column;   
}

.cardsContainer .standby {
  opacity: 0.7;
}

.cardsContainer .cardOff {
  opacity: 0.1;
  z-index: 10;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: var(--main-bg);  
  cursor: pointer;  
}
  
.card:nth-child(1):before, .card:nth-child(1):after {
  background: linear-gradient(25deg, #89ff00, var(--main-bg), var(--main-bg), var(--main-bg), #f714eb);
}
  
.card:before, .card:after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: #fff;
  border-radius: 50%;
  z-index: -1;  
}

.card-content {
 font-family: 'Lato', sans-serif; 
  padding: 20px;
  color: #fff;  
}

/* CARDS */

#mainCards {
  width: 100%; 
  margin-top: 4vw; 
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  justify-content: center;
  align-items: center;
}

.cardsContainer {  
  grid-column: 3 / 9;    
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.content {
  grid-column: auto / span 1;
  display: flex;
  flex-direction: column;   
  height: auto;   
  width: 100%;
  height: 100%; 
  transition: all 0.3s ease-in-out;
}

.card {  
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;  
  background-color: #1F2387;
  border-radius: 1.7vw;  
  cursor: pointer;    
}

#mainCards .card {
  margin: 0;
}

.card:nth-child(1):before, .card:nth-child(1):after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.30), transparent);
}
  
.card:before, .card:after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: #fff;  
  z-index: -1;  
  border-radius: 1.7vw;
}

.card-content {
 font-family: 'Lato', sans-serif; 
  padding: 0% 10% 5%;
  color: #fff;  
}

.card-content h4 {
  font-family: 'Tango Sans', sans-serif;  
  color: #fff;  
  font-size: 2vw;
  line-height: 2vw;  
}

.card-content p {
  font-family: 'Tango Sans', sans-serif;  
  color: #fff;  
  font-size: 1vw;
  line-height: 1.2vw;  
}

.mainLinksContainer {
  grid-column: 1 / 4;
  margin-top: 3.5vw;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  align-items: center;
  height: 15vw;
  border-radius: 7.5vw;
  background: linear-gradient(to right, #00046B 0%, transparent 100%); 
}

/* @import url('./images/main/mainLinkBg-01.svg'); */

.mainLinks {
  height: 5.5vw;
  width: 21vw;
  border-radius: 2.75vw;    
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px solid #bdccd442;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: 'Tango Sans', sans-serif;
  font-size: 2vw;  
}

.linktoGP, .linktoSP {
  background-image: url(../../../../../public/images/main/mainLinkBg-01.svg);  
  transition: all 0.5s ease-in-out;
}

.linktoSP:hover {
  background-image: url(../../../../../public/images/main/link-sp.svg); 
}
.linktoGP:hover {
  background-image: url(../../../../../public/images/main/link-gp.svg); 
}

.animationNull {
  width: auto;
  height: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;  
  margin: 0 auto;
  /* border: solid 1px red; */
  margin-bottom: 1vw;
}

.active {
  position: relative;  
  z-index: 20;
}

.auxCards {
  width: 15vw;
  height: auto;  
  position: absolute;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: -1;
}

.auxA {  
  right: -16vw;  
}

.auxB {  
  bottom: -14vw;  
}

.auxC {  
  left: -16vw;  
}

.auxE {  
  top: -18vw;  
}

.auxCards p {
  width: 100%;
  padding: 0.5vw; 
  background: linear-gradient(90deg, rgba(255,99,99,0.5) 0%, rgba(52,22,119,0.1) 100%);  
  text-align: center;
  border-radius: 1vw;
  border: 2px solid rgb(133, 137, 151);
  margin-bottom: 1vw;
  font-size: 1.2vw;
  /* font-size: max(1.2vw, 16px); */
  font-weight: 400;  transition: all 0.5s ease-in-out;
  
}

.auxCards .defaseA {
  transform: translate(-16.5vw, 0) !important;  
  opacity: 0;
}

.auxCards .defaseB {
  transform: translate(0, -14vw) !important; 
  opacity: 0;
}

.auxCards .defaseC {
  transform: translate(16vw, 0) !important; 
  opacity: 0;
}

.auxCards .defaseE {
  transform: translate(0, 18vw) !important;
  opacity: 0; 
}

.auxCards p span {
  color: var(--fourth-color); 
  font-size: 1.2vw;
  /* font-size: max(1.2vw, 16px); */
  font-weight: 500;
}

.auxCards p .pSmall {
  color: white; 
  font-size: 0.8vw;
  /* font-size: max(0.8vw, 12px);  */
  line-height: 0.8vw;     
  font-weight: 400;
  /* margin-bottom: -100px; */
}

/* Mobile */
@media screen and (max-width: 768px) {
  #home .cardsContainer { 
    margin-top: 5vw; 
    display: grid;    
    grid-template-columns: repeat(4, 1fr);
    gap: 7vw;  
    width: 100%;
  }

  #home .content {
    grid-column: auto / span 4;  
    width: 80%;
    margin-inline: auto;  
  }

  #home .card {    
    background-color: #0a0d5f;  
    background-image: url(../../../../../public/images/sector_privado/card-bg-red.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 1.7vw;  
    cursor: pointer;  
  }

  #home .card:nth-child(1):before, #home .card:nth-child(1):after {
    background: linear-gradient(90deg, rgba(211, 255, 51, 0.3), transparent) ;
  }
 
  #home .card-content h4 {     
    font-size: 4vw;
    line-height:4vw; 
    text-align: center;      
  }
  
  #home .card-content p {    
    font-size: 2.5vw;
    line-height: 3.5vw;  
    text-align: center;
  }
  
  .cardsContainer .standby {
    opacity: 1;
  }

  .mainLinksContainer {
    grid-column: 1 / 5;
    margin-top: 3.5vw;    
    flex-direction: column;    
    height: auto;    
    border: 2px solid #bdccd46b;
  }

  .mainLinks {
    height: 11vw;
    width: 75%;
    border-radius: 5.5vw;  
    margin-bottom: 4vw;  
    font-size: 4vw;  
    background-size: cover;   
  }

  .linktoGP {
    margin-top: 4vw;
    border: solid 2px#f1ff3371;     
  }

  .linktoSP {    
    border: solid 2px#7928ca71;    
  }

  .auxCards p span {    
    font-size: 3vw;    
    font-weight: 500;
  }

  .auxCards {
    width: 80%;   
    margin-inline: auto; 
  }    

  .auxCards p {
    width: 100%;
    padding: 0.5vw; 
    border-radius: 4vw;
    border: 2px solid rgb(133, 137, 151);
    margin-bottom: 1vw;
    font-size: 3vw;  
    line-height: 3vw;  
    font-weight: 400;  
    transition: all 0.5s ease-in-out;    
  }
  
  .auxCards p .pSmall {     
    font-size: 2vw;    
    line-height: 2vw;  
  }

  .auxB {
    bottom: -23vw;
    left: 4vw;
  }

  .auxE {
    top: -30vw; 
    left: 4vw;
  }

  .auxB-A {
    bottom: -28vw;
    left: 4vw;
  }

  .auxB-D {
    bottom: -33vw;
    left: 4vw;
  }

  .auxCards .defaseB-A {
    transform: translate(0, -28vw) !important; 
  }

  .auxCards .defaseB-D {
    transform: translate(0, -27vw) !important; 
  }

  #home .card:before, #home .card:after {    
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;    
  }

  #home .card {    
    border-radius: 4.5vw;     
  }

  #home .card:before, #home .card:after {    
    border-radius: 4.5vw;
  }

  #home .card:hover {  
    border-radius: 4.5vw;       
  }
    

}

