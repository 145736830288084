#presentation {
  /* width: 200%; */
  height: 80vh;   
  display: flex;
  flex-direction: row;  
  overflow-x: hidden; 
  position: relative;  
}

.previusArrow {
  position: absolute;
  top: 50%;
  left: 11.25vw;
  transform: translate(-50%, -50%) rotate(180deg);
  height: 2.7vw;
  width: auto;
  z-index: 20;
  cursor: pointer;
}

.nextArrow {
  position: absolute;
  top: 50%;
  right: 11.25vw;
  transform: translate(-50%, -50%);
  height: 2.7vw;
  width: auto;
  cursor: pointer;
}

#slider {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  white-space: nowrap;  
  overflow-x: hidden;  
}

.placaContainer {
  display: inline-block;
  width: 100%;
  height: 100%;  
  height: calc(100% - 5px);  
  position: relative;   
}

.placa {
  width: 100%;   
  height: 80vh;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  margin: 0;  
  padding: 0;  
  position: absolute;
  overflow: hidden;
}

.placa h1 {
  pointer-events: none !important;
}

#presentation h1 {   
  font-family: 'Tango Sans', sans-serif;
  font-size: 5vw;
  text-align: center;   
  line-height: 5vw;
  z-index: 16;  
}

#presentation h1 span {   
  font-family: 'Tango Sans', sans-serif;
  font-size: 5vw; 
  display: flex; 
  height: 5.5vw;
  background: rgb(241,255,51);
  background: linear-gradient(90deg, rgba(241,255,51,1) 0%, rgba(109,214,51,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
}


.cardMap {
  width: 7.5vw;
  height: 3.2vw;  
  border-radius: 1.6vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: solid 2px rgba(255, 255, 255, 0.068);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cardMapCircle {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  border: solid 1px var(--second-color);  
  transition: all 0.5s ease;
}

.circleMapActive {
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  border: solid 1px var(--second-color); 
  background-color: var(--second-color); 
  box-shadow: 0px 0px 9px 0px rgba(228, 253, 0, 0.75);
  transition: all 0.5s ease;
}

/* Mobile */
@media screen and (max-width: 768px) {
  #presentation {
    box-shadow: 0px 14px 9px -2px rgba(0,0,0,0.07);
    -webkit-box-shadow: 0px 14px 9px -2px rgba(0,0,0,0.07);
    -moz-box-shadow: 0px 14px 40px -2px rgba(0,0,0,0.07);  
  }

  #presentation h1, #presentation h1 span  {      
    font-size: 7.2vw;
    line-height: 7.2vw;         
  }
  #presentation h1 span  {      
    font-weight: 800;  
    height: 7.5vw;      
  }

  #presentation {    
    height: 50vh;
  }

  .placa {
    height: 100%;
  }

  .previusArrow {    
    left: 5vw;  
    height: 6vw;  
  }
  
  .nextArrow {    
    right: 5vw;    
    height: 6vw; 
  }

  .cardMap {
    width: 20vw;
    height: 5.4vw;  
    border-radius: 2.7vw;    
  }

  .cardMapCircle, .circleMapActive{
    width: 3vw;
    height: 3vw;    
  } 

  #presentation, #slider {    
    overflow-x:scroll      
  }

  #slider {
    white-space: nowrap;
    scroll-behavior: "smooth";
    scroll-snap-type: x mandatory;
  }

  #slider::-webkit-scrollbar {
    display: none;
  }

  #slider .placaContainer {
    scroll-snap-type: y mandatory;
  }

  #slider .placaContainer {
    scroll-snap-align: start;
  }


}