footer {
  border-bottom: 1rem solid var(--fourth-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 90;
  position: relative;
}

footer img {
 width: 10vw;
 height: auto;
 padding-bottom: 3vw; 

 @media screen and (max-width: 768px) { 
    width: 20vw;
    padding-bottom: 2vw;
  } 

}

.white {
  background-color: #e9e9e9;
}

.yellow, .redish {
  border-bottom: 1rem solid #0462BF !important;
}