.profileHeader {
  border-top: 1rem solid;
  border-image: linear-gradient(90deg, rgba(241,255,51,1) 0%, rgba(255,99,99,1) 80%, rgba(52,22,119,1) 100%) 10;  
  background-color: #ebebeb;

  @media screen and (max-width: 1000px) { 
    padding-top: 4vw;
    padding-bottom: 4vw;
  }

  @media screen and (max-width: 768px) { 
    padding: 6vw 6.5vw;
  }

  & .navBar a, &.navBar h3 {
    color: #341677;
  }

  & .navBar a:hover, & .navBar h3:hover {
    color: #ff6363;
  }

  & .iconsContainer {
    position: absolute;
    right: 0;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 8vw; 

    & .mailsContainer, & .notifContainer {
      width: 1.5vw;
      height: 1.5vw;
      margin-right: 1vw;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;

      @media screen and (max-width: 1000px) { 
        width: 3vw;
        height: 3vw;
      }

      @media screen and (max-width: 768px) { 
        width: 6vw;
        height: 6vw;
      }

      & img {
        width: 100%;
        height: auto;      
      }

      & .mailNotification {
        position: absolute;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ff6363;
        top: 10%;
        right: -20%;
      }

      
      & #userMsn {
        background-color: #f1f1f1;
        position: absolute;
        top: 5vw;
        right: -50%;
        width: 20vw;
        height: auto;
        border-radius: 0.5vw;
        box-shadow: 7px 7px 9px -2px #35167746;
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        cursor: auto;

        @media screen and (max-width: 1000px) { 
          width: 60vw;
        }

        @media screen and (max-width: 768px) {           
          top: 14vw;
          left: 50%;
          transform: translate(-55%, 0);
          border-radius: 2vw;
        }

        & .msgContainer {
          width: 100%;
          height: auto;
          padding: 0.5rem 1rem; 
          display: grid;           
          grid-template-columns: repeat(10, 1fr);

          & .msgPointer {
            grid-column: 1 / span 1;
            display: flex;
            justify-content: center;
            align-items: center;   
            padding: 0 0 1rem;     
            border-bottom: 1px solid #35167718 ;      

            & div {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #ff6363;
            }

            & .msgPointerFalse {              
              background-color: #afafaf;
            }

          }

          & .msgText {
            grid-column: 2 / span 9;             
            width: 100%; 
            padding: 0 0 1rem; 
            border-bottom: 1px solid #35167718 ;    
            
            @media screen and (max-width: 768px) {           
              padding: 0 0 0.5rem;
            }

            & p {
              color: #341677;
              width: 100%;              

              @media screen and (max-width: 768px) {           
                font-size: 0.8rem;
              }
            }

            & .msgTextfalse {
              color: #afafaf;
              width: 100%;
            }
          }
          
        }
      }
    }
  

    & .userInitial {
      width: 2.5vw;
      height: 2.5vw;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(52,22,119);
      background: linear-gradient(45deg, rgba(52,22,119,1) 0%, rgba(255,99,99,1) 100%);
      margin-right: 0.5vw;
      position: relative;
      cursor: pointer;   
      z-index: 25;

      @media screen and (max-width: 1000px) { 
        width: 4vw;
        height: 4vw;
      }

      @media screen and (max-width: 768px) { 
        width: 8vw;
        height: 8vw;
        margin-left: 4vw;
      }

      & p {        
        @media screen and (max-width: 500px) { 
          font-size: 0.8rem;
        }
      }
      
      & .logoutButton {
        position: absolute;
        margin-top: 3.2rem;
        top: 100%;
        padding: 1rem 2rem;
        background-color: #f1f1f1;
        border-radius: 0.5vw;
        border: none;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        box-shadow: 7px 7px 9px -2px #35167746;
        color: #341677;

        &:hover {
          transform: scale(1.1);
        }

        @media screen and (max-width: 768px) { 
          top: 0;
          right: 0;
        }

      }
    }

    .menuIcon {
      background-color: #ff636334;
      border-radius: 1vw;
      width: 5vw;
      height: 4.5vw;
      border: 1px #FF6363 solid;     
      margin: 2.5vw 2vw 2.5vw 0;
      
      @media screen and (max-width: 768px) { 
        width: 11vw;
        height: 9.5vw;
        margin: 2.5vw 3.6vw;
      }
    }
  }  

  .menuMobile {
    background: #F1F1F1;
    border-radius: 2vw; 
    justify-content: space-around;
    /* border: 2px solid rgb(216, 216, 216);  */ 
    top: 18vw;    
    box-shadow: 7px 7px 9px -2px #35167746;
    width: 38vw;
    

    & a {
      width: 100%;      
      margin: 0;
      padding: 2.8vw 0;
      text-align: center;
      color: #341677;         
      box-shadow: 0px 4px 6px -2px rgba(255,99,99,0.15);
    }
    
    & .topLink {
      padding-top: 2vw;
    }
    
    & .btnLink {
      border-bottom: 1px solid transparent;   
      padding-bottom: 1.5vw;  
      box-shadow: none;
    }
    
  }

}



