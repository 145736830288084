.bluryBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(9, 2, 70, 0.151); 
  backdrop-filter: blur(2px);
  z-index: 26;
}

.resumeContainer {
  height: auto;
  position: relative;
  width: 54vw;
  height: auto;
  position: fixed;
  top: 8vw;
  left: 50%;
  transform: translate(-50%, 0);  
  z-index: 30;
}

.noisy {
  background-image: url(https://upload.wikimedia.org/wikipedia/commons/7/76/1k_Dissolve_Noise_Texture.png); 
  width: 54vw;
  height: 100%;
  border-radius: 3.6vw;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0.3;
  transition: all 0.5s ease-in-out;
  z-index: -1;
}

.noisy-2 {
  background-image: url(https://upload.wikimedia.org/wikipedia/commons/7/76/1k_Dissolve_Noise_Texture.png); 
  width: 54vw;
  height: 30vw;
  border-radius: 3.6vw;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0.3;
  transition: all 0.5s ease-in-out;
  z-index: 10;
}

#resumeContainer {
  width: 54vw;
  height: auto;
  background: rgb(52,12,119);
  background: linear-gradient(0deg, rgba(52,12,119,0.9) 0%, rgba(0,2,114,0.9) 100%);
  backdrop-filter: blur(5px);
  /* position: fixed; */
  border-radius: 3.6vw;
  border: solid 2px #ffffff9f;
  /* top: 11vw;
  left: 50%; 
  transform: translate(-50%, 0);*/
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  padding: 2vw 0;
  z-index: 99 !important;
}

#resumeContainer h2 {
  font-family: 'Tango Sans', sans-serif;
  color: var(--second-color);  
  font-size: 3.2vw;
  font-size: max(3.2vw, 24px);  
  background: rgb(109,214,51);
  background: linear-gradient(-90deg, rgba(109,214,51,1) 0%, rgba(241,255,51,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#resumeContainer img {
  width: 2vw;
  height: auto;
  object-fit: cover;
  margin: 1vw auto;
}

#resumeContainer p {  
  font-size: 1.5vw;
  font-size: max(1.5vw, 16px); 
  font-weight: 200; 
}

#resumeContainer .note {  
  padding: 1vw 2vw;
  border-radius: 1.8vw;
  border: solid 2px #ffffff65;
  text-align: center;
  background-color: #8e72e13d;
  margin-top: 1vw;
}

#resumeContainer form {  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1vw;
}

#resumeContainer form label{  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;  
}

#resumeContainer form .file-upload{  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;  
}

#resumeContainer form img{  
  height: 5.8vw;
  width: auto;
  object-fit: cover;      
  transition: all 0.2s ease-in-out;
}

#resumeContainer form button img {  
  height: 4.3vw;
}

#resumeContainer form .addImg{   
  cursor: pointer;    
}

#resumeContainer form img:hover{    
  /* transform: scale(1.1);   */ 
}

.checkResumeContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#resumeContainer form .file-upload h3 span{  
  font-family: 'Rubik', sans-serif;  
  font-size: 1.5vw;
  font-size: max(1.5vw, 16px); 
  font-weight: 200;  
  color: var(--second-color);
}

#resumeContainer form h3{  
  font-family: 'Tango Sans', sans-serif;    
  font-size: 2vw;
  font-size: max(2vw, 20px);  
  line-height: 1.5vw;
  padding: 0 1.3vw;
}

#resumeContainer form h3 span{  
  font-family: 'Rubik', sans-serif;  
  font-size: 1.5vw;
  font-size: max(1.5vw, 16px); 
  font-weight: 200;  
  color: #5067D8;
}

.file-upload button {
  position: absolute;
  bottom: 2vw;
  right: 8vw;
  background: none;
  border: none;
}

.sendImg {
  background: rgb(0,21,156);
  background: linear-gradient(-45deg, rgba(0,21,156,1) 0%, rgba(33,59,133,1) 100%);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

#resumeContainer .file-upload .disableBtn img {
  background: rgb(0,21,156);
  background: linear-gradient(-45deg, rgb(135, 135, 138, 0.5) 0%, rgb(112, 113, 114, 0.1) 100%);
}

.sendImg:hover {
  background: rgb(241,255,51);
  background: linear-gradient(135deg, rgba(241,255,51,1) 0%, rgba(241,255,51,0) 100%); 
}

/* Drag Container */
#drag {
  transition: all 0.2s ease-in-out;
}

.dragContainer {
  width: 100%;
  height: 100%;
  background: rgb(255,99,99);
  background: linear-gradient(0deg, rgba(255,99,99,0.65) 0%, rgba(183,41,146,0.65) 100%);
  background-image: url(../../../public/images/resume/drag-01.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  border-radius: 3.6vw;  
  top: 0vw;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  z-index: 30;
  animation-name: animacion-imagen;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes animacion-imagen {
  0% {
    background-size: 110% 110%;
  }
  50% {
    background-size: 125% 125%;
  }
  100% {
    background-size: 110% 110%;
  }
}

.dragContainer h3 {
  font-family: 'Tango Sans', sans-serif;   
  font-size: 5vw;
  font-size: max(5vw, 2rem);  
  text-align: center;  
}

.dragContainer P {
  font-size: 1.5vw;
  font-size: max(1.5vw, 16px); 
  font-weight: 200;    
  text-align: center;
}

/* Form */
.resumeForm input {
  width: 100%;
  height: 3.5vw;
  border-radius: 1.75vw;
  border: 1px solid #54b6eb33;
  padding-left: 2vw;  
  font-size: max(1.3vw, 14px);
  font-weight: 200;
  margin-bottom: 0.7vw;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
}

.resumeForm input:focus, .resumeForm textarea:focus {
  outline: none;  
}

.resumeForm textarea {
  width: 100%;
  height: 10vw;
  border-radius: 1.75vw;
  border: 1px solid #54b6eb33;
  padding-left: 2vw;
  padding-top: 1vw;
  font-size: max(1.3vw, 14px);  
  font-weight: 200;
  margin-bottom: 1.5vw;
  background-color: transparent;
}

.resumeForm textarea::placeholder, .resumeForm input::placeholder {
  color: #fff;
}

.resumeForm input:focus, .resumeForm textarea:focus {
  border-color: var(--fourth-color);
  box-shadow: 0vw 0vw 1vw 1vw rgba(255,101,101,0.10);
  -webkit-box-shadow: 0vw 0vw 1vw 1vw rgba(255,101,101,0.10);
  -moz-box-shadow: 0vw 0vw 1vw 1vw rgba(255,101,101,0.10);
}

@media screen and (max-width: 768px) {
  .resumeContainer {
    width: 90vw;   
    /* height: 60vh; */
  }
  
  #resumeContainer {    
    width: 90vw; 
    /* height: 60vh; */ 
    justify-content: space-between;
    align-items: center;  
    padding: 5vw 0;   
  }

  #resumeContainer .note { 
    margin: 2vw 5vw; 
    padding: 1vw 1vw;
    border-radius: 1.8vw;
    border: solid 2px #ffffff65;
    text-align: center;
    background-color: #8e72e13d;
    margin-top: 1vw;
    font-size: 1vw;
  }

  #resumeContainer .note p {     
    font-size: 4vw;
  }

  .checkResumeContainer {    
    flex-direction: column;    
    text-align: center;
    margin-bottom: 2vw;
  }

  #resumeContainer form h3{ 
    font-size: 4vw;     
    line-height: 4vw;
    padding: 0 1.3vw;
  }

  .resumeForm input {
    width: 100%;
    height: 10vw;
    border-radius: 5vw;    
  }

  .resumeForm textarea {    
    height: 20vw;
    border-radius: 5vw; 
    padding-top: 2vw;   
  }

}
