.upArrowContainer{
  position: fixed;
  bottom: 2vw;
  right: 2vw;
  width: 4vw; 
  height: 4vw;
  border-radius: 50%;  
  background: linear-gradient(-45deg, rgba(0,21,156,1) 0%, rgba(33,59,133,1) 100%);
  border: 1px white solid;
  display: flex;
  justify-content: center;
  align-items: center;  
  margin: 0 1rem 1rem;
  transform: rotate(-90deg);
  cursor: pointer; 
  transition: all 0.3s ease-in-out;
  z-index: 99;

  @media screen and (max-width: 768px) { 
    width: 6vw; 
    height: 6vw;
  } 

  @media screen and (max-width: 576px) { 
    width: 8vw; 
    height: 8vw;
  } 
}

.upArrowContainer:hover {
  /* background: rgb(241,255,51); */
  background: linear-gradient(-45deg, rgba(241,255,51,0.5) 0%, rgba(241,255,51,0) 100%); 
  transform: scale(1.1) rotate(-90deg)  ;
}

.upArrowContainer img {
  height: 50%;
  width: auto;
}

.profileArrow {
  background: linear-gradient(-45deg, rgba(52,22,119,0.41) 0%, rgba(255,99,99,0.41) 100%);

  @media screen and (max-width: 768px) { 
    height: 8vw;
    width: 8vw;
    right: 0px;
  }
}

.profileArrow:hover {
  background-image: url(../../../public/images/profile/arrow-profile.svg);
}