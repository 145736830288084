@keyframes pulse {
	0% {
		background-size: 80%;
	}

	50% {
		background-size: 150%;
	}

	100% {
		background-size: 80%;
	}
}

#clientsView {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;    

    .searchContainer {
        margin: 1rem auto;

        input {
            width: 20rem;   
            height: 2.5rem;
            border-radius: 10vw;
            border: none;
            background-color: rgba(255, 255, 255, 0.2);
            color: #341677;
            padding: 0 1rem;
            font-size: 1rem;
            font-size: max(1rem, 14px);
            font-weight: 200;
            outline: none;
            border: solid 2px white;
            max-width: 80vw;

        }
    }

    .client {
        border: solid 2px white;
        padding: 1rem 5rem;
        border-radius: 1rem;
        margin-bottom: 1rem;
        background: linear-gradient(to right, rgba(255, 255, 255, 0.20) 0%, rgba(35, 5, 119, 0.05) 100%);

        @media screen and (max-width: 576px) { 
            width: 80vw;  
            padding: 1rem 1.5rem; 
        } 
        
        h3 {
            font-size: 1.5rem;
            color: #341677;
            margin-bottom: 1.5vw;
        }

        p {      
            padding: 0.5vw 3vw;      
            color: #351677b7;
            font-weight: 200;
            border-radius: 1.75vw;
            border: 1px solid #54b6eb33;
            font-size: max(1vw, 14px);
            font-weight: 200;
            margin-bottom: 0.7vw;
            background-color: rgba(255, 255, 255, 0.2);
            transition: all 0.2s ease-in-out;
            color: rgb(11, 11, 70);
            text-align: left;
            
            span {
                font-size: max(1.1vw, 14px);
                font-weight: 400;
                color: #341677;
                
            }
        }

        button {
            background-color: var(--fourth-color);
            width: auto;
            height: auto;
            border: transparent;
            border-radius: 1vw;
            padding: 0.8vw 2vw;
            font-family: 'Tango Sans', sans-serif;
            font-size: 1.3vw;
            font-size: max(1.3vw, 14px);
            color: #fff;
            margin: 1rem auto;
            display: block;
            cursor: pointer;
            transition: all 0.5s ease-in-out;

            &:hover {
            background-color: #341677;
            }
        }
    }
}

.modalContainer {

    position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(4px);
	z-index: 25;
    display: flex;
    justify-content: center;
    padding-top: 20vh;
   
    

    .deleteModalContiner {        
        width: 60vw;
        height: 60vh;
        border: solid 2px white;
        border-radius: 2rem;
        box-shadow: 0 0 10px 5px rgba(212, 156, 210, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(45deg, rgba(52,22,119,0.8) 0%, rgba(206, 99, 255, 0.8) 100%);
        color: white;

        @media screen and (max-width: 768px) {
			padding: 1rem;
			width: 80vw;
			height: 40vh;
		}

        .deleteModal {

            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-image: url(../../../../../public/images/profile/atetion.svg);        
			background-position: center;
            background-repeat: no-repeat;
            align-items: center;
			animation: 6s linear 0s infinite alternate pulse;

            h2 {
                font-size: 20px;
                font-size: max(2vw, 20px);
                color: white !important;
                font-weight: 200;
                margin-bottom: 0.5rem;    
            }

            p {
                font-size: max(1vw, 16px) !important;
                color: white !important;
                font-weight: 200;
                margin-bottom: 2rem;                
            }
    
            div {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-bottom: -10vh;
    
                button {
                    width: 20rem;
                    height: 2.5rem;
                    margin-bottom: 1rem;
                    border-radius: 10vw;
                    text-align: center;
                    font-size: 1.2rem;
                    border: 2px rgba(255, 255, 255, 0.479) solid;
                    background: #f1ff33;
                    background: linear-gradient(45deg,rgba(255, 167, 51, 0.2),rgba(255, 167, 51,.7));
                    cursor: pointer;
                    transition: all 0.4s ease-in-out;

                    &:hover {
                        transform: scale(1.05);
                    }

                    @media screen and (max-width: 768px) {
                        width: 90%;
                    }
                }
            }
        }

    }
}
