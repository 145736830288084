.patronContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(6, 1.5fr);
  position: absolute;
}

.gradientBG {
  width: 100%;
  height: 100%;  
  position: absolute;
  background: rgb(0,2,95);
  background: linear-gradient(180deg, rgba(0,2,95,.8) 20%, rgba(0,2,95,0) 100%);
  pointer-events: none !important;
}

#presentation .placa h1 .spanA {
  height: 14vw;
  text-align: center;
  display: block;
}

.patron {  
  width: 100%;  
  height: 100%;  
  transition: all 1s ease;
  transform: rotate(45deg); 
  display: flex;
  justify-content: center;
  align-items: center;
}

.patron div{  
  width: 55%;  
  height: 100%;   
  border-radius: 5vw;    
  box-shadow: 1px 1px 3px 0px rgba(224,247,2,0.3);
  pointer-events: none !important;
}

.hovered{
  transform: rotate(-45deg); 
  transition: all 1s ease;
}

@media screen and (max-width: 768px) {
  .patronContainer {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(auto, 1.5fr)
  }

  #presentation .placa h1 .spanA {
    height: 14vw;
    text-align: center;
    margin: auto 0;
    display: block;
  }
}





