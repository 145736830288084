#why {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  justify-content: center;
  align-items: center;
  background-image: url(../../../../public/images/gestion_publica/waves-01.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding-bottom: 6vw;
}

.whyContainer {
  grid-column: 3 / 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.whyContainer h2 {
  font-family: 'Tango Sans', sans-serif;
  color: var(--second-color);  
  font-size: 3vw;
  font-size: max(3vw, 24px); 
  margin-bottom: 2.2vw; 
}

.whyContainer h3 {  
  color: var(--second-color);
  background-color: #bdccd420; 
  border: solid 1px  #bdccd450;
  font-size: 1.8vw;
  font-size: max(1.8vw, 18px);
  padding: 1.35vw;  
}

.whyInfo {
  width: 75%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  align-items: center;
  margin-top: 6vw;
}

.whyInfo img {
  grid-column: 1 / 2;
  width: 100%;
  height: auto;
  object-fit: cover;
  margin: 1.3vw auto; 
}

.whyInfo p {
  grid-column: 2 / 7;
  height: 100%;
  font-size: 1.5vw;
  font-size: max(1.5vw, 16px);
  line-height: 1.5vw;
  line-height: max(1.5vw, 16px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 2vw;
 /*  line-height: 2.5vw;
  line-height: max(2.5vw, 24px);
  padding: 0 1.5vw; */
}

/* @media screen and (max-width: 1024px) {
  .whyInfo p {       
    font-size: 2.5vw;    
    line-height: 2.5vw;   
  }
} */

@media screen and (max-width: 768px) {
  #why {  
    padding-top: 12vw;
  }

  .whyContainer {
    grid-column: 2 / 10;    
  }

  #gestionPublica .whyContainer h2 {
    width: 100%;
    text-align: center;    
    font-size: 5vw;  
    line-height: 4.5vw;
  }

  .whyContainer h3 { 
    font-size: 3vw
  }

  .whyInfo {
    width: 100%;    
  }

  .whyInfo img {    
    width: 90%;    
  }
  
  .whyInfo p {       
    font-size: 3.2vw;    
    line-height: 3.2vw;   
  }
}

