#admin {
  width: 100%;
  background-color: #e9e9e9;
  text-align: center;
  min-height: calc(100vh - 6.5vw);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 25vw;

  @media screen and (max-width: 768px) { 
    min-height: calc(100vh - 26vw);       
  } 


  h1 {
    margin-top: 2rem;
    font-size: max(5.3vw, 2rem);
    color: #341677;
    z-index: 10;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 768px) { 
      width: 80%;
      font-size: max(7.2vw, 2rem);
    }
  }
  
  p {
    font-size: max(2.3vw, 1rem);
    color: #341677;
    z-index: 10;
    transition: all 0.2s ease-in-out;

    @media screen and (max-width: 768px) { 
      font-size: max(4vw, 1rem);
    }
  } 
  
  .noAdminText {
    margin-bottom: 4rem;
  }
  
}

.backButton {  
  width: 40vw;
  width: auto;
  margin-bottom: 1rem;
  padding: 1vw 2vw;
  border-radius: 3.2vw;
  border: 3px solid white;
  font-size: max(2vw, 1.5rem);
  font-family: 'Tango Sans', sans-serif;
  background: -webkit-linear-gradient(360deg, #341677, #ff6363);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  cursor: pointer;
  transition: all ease-in-out 0.3s;  

  &:hover {
    /* border: solid 4px rgb(204, 147, 182); */
    box-shadow: 0 0 10px 1px rgb(148, 102, 130, 0.5);
  }

  @media screen and (max-width: 576px) { 
    font-size: max(2vw, 1rem);
    width: 80vw;       
  } 
}

.actionForms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3vw auto; 
  width: 35vw;  

  background: linear-gradient(to right, rgba(255, 255, 255, 0.20) 0%, rgba(35, 5, 119, 0.05) 100%);
  border: 2px solid white;
  height: auto;
  border-radius: 3.5vw;

  @media screen and (max-width: 768px) { 
    width: 60vw;     
  } 

  @media screen and (max-width: 576px) { 
    width: 80vw;       
  }

  legend {
    width: 100%;    
    margin: 2.8vw auto 4vw;
    text-align: center;    
    font-size: 1.5vw;
    font-size: max(1.5vw, 14px);
    font-weight: 400;
    color: #341677;
  }

  .subLegend {
    width: 100%;    
    margin: -3.2vw auto 4vw;
    text-align: center;    
    font-size: 1.5vw;
    font-size: max(1.5vw, 14px);
    font-weight: 400;
    color: #341677;
  }

  input {
    width: 80%;
    /* height: 3vw;  */ 
    border-radius: 1.75vw;
    border: 1px solid #54b6eb33;
    padding-left: 3vw;  
    font-size: max(1.3vw, 16px);
    font-weight: 200;
    margin-bottom: 0.7vw;
    background-color: rgba(255, 255, 255, 0.2);
    transition: all 0.2s ease-in-out;
    color: rgb(11, 11, 70);

    &::placeholder {
      color: rgb(11, 11, 70, 0.5);
    }

    &:focus {
      outline: none;  
      background-color: rgba(255, 255, 255, 0.6);

      &::placeholder {
        color: rgb(11, 11, 70, 0.8);
      }
    }

    @media screen and (max-width: 768px) { 
      font-size: max(2.5vw, 14px);     
    } 
    
  }

  .selectBtn {
    width: 80%;
    height: 3vw;  
    border-radius: 1.75vw;
    border: 1px solid #54b6eb33;
    padding-left: 3vw;  
    font-size: max(1vw, 14px) !important;
    text-align: left;
    font-weight: 200 !important;
    margin-bottom: 0.7vw;
    background-color: rgba(255, 255, 255, 0.2);  
    color: rgb(11, 11, 70);
    display: flex;
    align-items: center;  
    cursor: pointer;
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  

  button {
    background-color: var(--fourth-color);
    width: 25%;
    height: auto;
    border: transparent;
    border-radius: 1vw;
    padding: 1.2vw 2vw;
    font-family: 'Tango Sans', sans-serif;
    font-size: 1.3vw;
    font-size: max(1.3vw, 14px);
    color: #fff;
    margin: 1rem auto 2rem;
    display: block;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    &:hover {
      background-color: #341677;
    }

    @media screen and (max-width: 768px) {
      display: flex; 
      justify-content: center;
      align-items: center;
      font-size: max(2.5vw, 14px);  
      padding: 0.5rem 2rem;   
    } 
    
  }

  .disabledBtn {
    background-color: grey;
    cursor: auto;

    &:hover {
      background-color: grey;
    }
  }

  .selectOptions {
    position: absolute;
    border-radius: 1rem;
    border: 1px solid #54b6eb33;
    width: auto;
    padding: 0 2rem;
    background-color: rgba(255, 255, 255);
    z-index: 25;
    p {
      width: 100%;
      font-size: 1.3vw !important;
      font-size: max(1.3vw, 14px) !important;
      border-bottom: 1px solid #54b6eb33;
      padding: 1.5vw 0;
      cursor: pointer;

      &:hover {        
        color: rgb(241, 104, 139) !important;
      }

      @media screen and (max-width: 768px) {

        font-size: 14px !important;  
        font-size: max(2.5vw, 14px) !important;  
           
      }
    }
  }


}