/* Mains Actions */

#actions {
  width: 100%;  
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin-top: 3vw;
  padding-top: 2vw;
}

.mainActionsContainer {
  grid-column: 4 / 10;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.mainActionsContainer h2 {
  grid-column: 1 / 7;
  font-family: Tango Sans, sans-serif;
  font-size: 3vw;
  font-size: max(3vw, 24px);  
  font-weight: 500;
  text-align: center;
  margin-bottom: 2vw;
}

.mainActionsContainer a {
  grid-column: 1 / 7;
  height: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;  
}

.mainActionsContainer a img {
  height: 100%;
  width: auto;  
}

#actions2 {
  margin-bottom: 5vw;
}

.mainAction {
  grid-column: auto / span 6;  
  display: flex;
  flex-direction: column;  
  align-items: center;
  justify-content: center;
  padding: 0 2vw;
  margin-bottom: 3vw;
}

.mainAction .imagesContainer {
  height: 3.5vw;
  min-height: 30px;
  width: auto; 
  position: relative;
  margin-bottom: 1rem; 
}

.mainAction .imagesContainer img {
  height: 100%;
  width: auto;
  opacity: 1;
  z-index: 5;
  position: relative;
}

.mainAction .imagesContainer .imgActive {
  position: absolute;
  opacity: 0;
  z-index: 10;
  transition: all 0.5s ease-in-out;
}

#actions .active .imagesContainer .imgActive {
  opacity: 1;
  z-index: 10;
}

.mainAction h3 {
  font-size: 1.6vw;
  font-size: max(1.6vw, 14px);
  text-align: center;
}

.mainAction h3 span {
  font-size: 1.6vw;
  font-size: max(1.6vw, 14px);
  color: #0F21A3;
  transition: all 0.5s ease-in-out;
}

#actions .active h3 span {
  color: var(--second-color);
}

/* Mobile */
@media screen and (max-width: 768px) {
  #actions {
    margin-top: 10vw;
    padding-bottom: 12vw;
  }

  .mainActionsContainer {
    grid-column: 2 / 12;
  }

  .mainActionsContainer h2 {    
    font-size: 6vw;
    line-height: 7vw;   
  }

  .mainActionsContainer a { 
    margin-top: 5vw;   
    height: 3vw;     
  }  

  .mainAction .imagesContainer {
    height: auto;    
    width: 9vw; 
    margin-top: 4vw;   
  }

  .mainAction h3, .mainAction h3 span  {
    font-size: 4vw;       
  }

  .mainAction h3 span  { 
    color: var(--second-color);     
  }

  .mainAction .imagesContainer .imgActive {    
    opacity: 1;    
  }

  #actions {
    box-shadow: 0px 14px 9px -2px rgba(0,0,0,0.07);
    -webkit-box-shadow: 0px 14px 9px -2px rgba(0,0,0,0.07);
    -moz-box-shadow: 0px 14px 40px -2px rgba(0,0,0,0.07);
  } 
  
 
}

