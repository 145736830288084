/* Form */

#redForm {
  display: grid;
  grid-template-columns: repeat(10, 1fr);  
  margin-top: 13vw;
  padding-bottom: 30vw;
  margin-bottom: calc(-1rem - 1.4vw - 3vw);
  background-image: url(../../../../public/images/sector_privado/form-back-01.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom left;  
  position: relative;
}

#redForm .formContainer::before{
  content: "";
  position: absolute;
  top: -5vw;
  left: 0;
  width: 100%;
  height: 6vw;
  background: rgb(9,9,121);
  background: linear-gradient(180deg, rgb(6, 6, 104) 0%, rgba(0,2,114,0) 100%);
  z-index: -20; 
}

/* MOBILE */

@media screen and (max-width: 768px) {
  #redForm .formContainer::before{
    display: none;
  }

  #sectorPrivado .formContainer legend {
    width: 79%;    
  }
}