header {
  position: relative;
  height: 6.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1rem solid var(--second-color);
  box-shadow: 0px 14px 9px -2px rgba(0,0,0,0.07);
  -webkit-box-shadow: 0px 14px 9px -2px rgba(0,0,0,0.07);
  -moz-box-shadow: 0px 14px 9px -2px rgba(0,0,0,0.07);
  z-index: 20;
}

.headerLogo {
  width: 14vw;
  height: auto;  
}

header .navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 58vw;
  margin: 0 auto;
  z-index: 25; 
}

header .navBar a, header .navBar h3 {
  text-decoration: none;
  color: var(--main-color);
  font-size: 1.25vw;
  font-size: 1rem;
  font-size: max(1.25vw, 14px);
  font-weight: 300;  
  cursor: pointer;
  transition: all 0.3s ease;
}

.rojo .navBar a:hover, .rojo .navBar h3:hover {
  color: var(--fourth-color);    
}

.rojo {
  border-top: 1rem solid var(--fourth-color);
}

/* MOBILE */
.menuIcon {
  width: 12vw;
  height: auto;
  cursor: pointer;   
}

.menuMobile {
  position: absolute;   
  width: 80vw;  
  aspect-ratio: 1/0.6;
  top: 15vw;
  left: 50%;
  transform: translateX(-50%);
  background: url(../../../../../public/images/main/menu_mobile_bg_2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  z-index: 90;  
  backdrop-filter: blur(3px);  
}

header .menuMobile a, header .menuMobile h3  { 
 font-size: 4.5vw; 
}

@media screen and (min-width: 768px) {

  .menuIcon {
    width: auto;
    height: 4vw;
    cursor: pointer;   
  }  

  header .menuMobile a, header .menuMobile h3  { 
    font-size: 3vw;
    padding: 2.5vw 0;
   }
}



@media screen and (max-width: 768px) {
  header {
    height: auto;  
    padding: 2.8vw 6.5vw; 
  }

  .headerLogo {
    width: 30vw;
    height: auto;  
  }

  header .navBar {    
    width: 100%;    
  }

}
