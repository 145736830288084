#presentation .placaE h1 span {     
  height: 12vw;  
}

.bigArrowContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; 
}

/* .bigArrowContainer:hover .bigArrowA {
  animation: slideshowA 5s linear infinite;
}

.bigArrowContainer:hover .bigArrowB {
  animation: slideshow 5s linear infinite;
} */



.bigArrowContainer .bigArrowA {
  background: url(../../../../../public/images/main/BigArrow-01.svg);
  background-repeat: no-repeat;
  background-position-y: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;  
  animation: slideshowA 7s linear infinite;
  background-size: cover;
}

.bigArrowContainer .bigArrowB {
  background: url(../../../../../public/images/main/BigArrow-01.svg);
  background-repeat: no-repeat;
  background-position-y: center;
  position: absolute;
  left: -100%;
  top: 0;
  height: 100%;
  width: 100%;  
  animation: slideshow 7s linear infinite;
  background-size: cover;
}


/* Infinite Desplazament animation */
@keyframes slideshowA {
  0%    { left: 0; }
  100%  { left: 100%; }
}

@keyframes slideshow {
  0%    { left: -100%; }
  100%  { left: 0%; }
}



/* Mobile */
@media screen and (max-width: 768px) {
  #presentation .placaE h1 span {     
    height: 21vw;  
  }

  .bigArrowContainer .bigArrowA {
    background-size: cover;
    animation: slideshowA 5s linear infinite;
    
  }
  
  .bigArrowContainer .bigArrowB {
    background-size: cover;
    animation: slideshow 5s linear infinite;
  }
}
