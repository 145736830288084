
/* Form */
#yellowForm {
  display: grid;
  grid-template-columns: repeat(10, 1fr);  
  margin-top: 13vw;
  padding-bottom: 30vw;
  margin-bottom: calc(-1rem - 1.4vw - 3vw);
  background-image: url(../../../../public/images/gestion_publica/form-back-01.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom left;  
  position: relative;
}

#yellowForm .formContainer::before{
  content: "";
  position: absolute;
  top: -5vw;
  left: 0;
  width: 100%;
  height: 6vw;
  background: rgb(9,9,121);
  background: linear-gradient(180deg, rgb(6, 6, 104) 0%, rgba(0,2,114,0) 100%);
  z-index: -20; 
}

#yellowForm .formContainer input:focus, #yellowForm .formContainer textarea:focus {
  border-color: var(--second-color);
  box-shadow: 0vw 0vw 1vw 1vw rgba(240, 255, 101, 0.1);
  -webkit-box-shadow: 0vw 0vw 1vw 1vw rgba(240, 255, 101, 0.1);
  -moz-box-shadow: 0vw 0vw 1vw 1vw rgba(240, 255, 101, 0.1);
}

#yellowForm .formContainer  .submitBtn {  
  background-color: var(--second-color);
  width: 25%;
  height: auto;
  border: transparent;
  border-radius: 1vw;
  padding: 1.2vw 2vw;
  font-family: 'Tango Sans', sans-serif;
  font-size: 1.3vw;
  font-size: max(1.3vw, 14px);
  color: blue;
  margin: 1rem auto 2rem;
  display: block;
  cursor: pointer;
}


#yellowForm .formContainer .iconsContainer .whatsapp-icon:hover {
  background-image: url(../../../../public/images/main/wa-icon-active-y.svg);
  -webkit-filter: drop-shadow(0px 0px 4px rgba(241,255,51,0.75));
  filter: drop-shadow(0px 0px 4px rgba(241,255,51,0.75));  
}

#yellowForm .formContainer .iconsContainer .linkedin-icon:hover {
  background-image: url(../../../../public/images/main/llinkedin-icon-active-y.svg);  
  -webkit-filter: drop-shadow(0px 0px 4px rgba(241,255,51,0.75));
  filter: drop-shadow(0px 0px 4px rgba(241,255,51,0.75));
}

#yellowForm .formContainer .iconsContainer .mail-icon:hover {
  background-image: url(../../../../public/images/main/mail-icon-active-y.svg);  
  -webkit-filter: drop-shadow(0px 0px 4px rgba(241,255,51,0.75));
  filter: drop-shadow(0px 0px 4px rgba(241,255,51,0.75));
}

.circleForm {
  position: absolute;
  width: 27vw;
  height: 27vw;
  border-radius: 50%;  
  right: 8vw;
  top: 8vw;
  border: solid 2px rgba(255, 255, 0, 0.473);
  background: rgb(65,104,176);
  background: linear-gradient(0deg, rgba(65,104,176,0.3) 0%, rgba(65,104,176,0) 100%);
  animation-name: mover-rotar;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes mover-rotar {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(-10%, 15%);
  }
  66% {
    transform: translate(-10%, -5%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 768px) {
  .circleForm {
   border: solid 1px rgba(255, 255, 0, 0.473);
  }

  /* MOBILE */


  #gestionPublica .formContainer::before{
    display: none;
  }

  #gestionPublica .formContainer legend {
    width: 79%;    
  }

}
